import { Outlet, useOutletContext } from '@remix-run/react';
import { LayoutContext } from '~/root';
import { LayoutContent } from '~/components';

export default function ContentLayout() {
  const { layout, locale } = useOutletContext<LayoutContext>();

  return (
    <LayoutContent layout={layout}>
      <Outlet />
    </LayoutContent>
  );
}
